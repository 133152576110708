/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `OrderExport.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { OrderExport } from "./OrderExport";

import { Project } from "./Project";

export type exportType = "system_export" | "user_export" | "user_import";

export const exportTypeValues = {"system_export":0,"user_export":1,"user_import":2};

export abstract class OrderExportModel {
    id: number;
    projectId: number;
    exportType: exportType;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    project: Project | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        projectId: number,
        exportType: exportType,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        project: Project | null | undefined
    ) {
        this.id = id;
        this.projectId = projectId;
        this.exportType = exportType;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.project = project;
    }
     

    static fromApiObject(json: any, timezone: string): OrderExport {
        const newObject = new OrderExport(
            json["id"],
            json["project_id"],
            json["export_type"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            project_id: this.projectId,
            export_type: this.exportType,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): OrderExport {
        const clone = Object.create(this) as OrderExport;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
