/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Audit.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Audit } from "./Audit";

import { User } from "./User";

export abstract class AuditModel {
    id: number;
    auditableType: string;
    auditableId: number;
    userId: number | null;
    change: string | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    user: User | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        auditableType: string,
        auditableId: number,
        userId: number | null,
        change: string | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        user: User | null | undefined
    ) {
        this.id = id;
        this.auditableType = auditableType;
        this.auditableId = auditableId;
        this.userId = userId;
        this.change = change;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.user = user;
    }
     

    static fromApiObject(json: any, timezone: string): Audit {
        const newObject = new Audit(
            json["id"],
            json["auditable_type"],
            json["auditable_id"],
            json["user_id"],
            json["change"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            auditable_type: this.auditableType,
            auditable_id: this.auditableId,
            user_id: this.userId,
            change: this.change,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Audit {
        const clone = Object.create(this) as Audit;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
