import Helper from "../../Helper";
import { Order } from "../../models/Order";
import { OrderCheckIn } from "../../models/OrderCheckIn";
import { plotFetch } from "../Helper/ApiRequest";
import { ChangeHistoryPoint } from "../Materials/materialTypes";

export function createOrder(
    team_subdomain: string,
    project_token: string,
    order: Order,
    material_id: number,
    timezone: string
): Promise<Order | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders";

    return plotFetch(
        url,
        {
            method: "POST",
            body: JSON.stringify({ order: order.toApiObject(), material_id: material_id }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return Order.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function updateOrder(
    team_subdomain: string,
    project_token: string,
    order: Order,
    timezone: string
): Promise<Order | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id;

    return plotFetch(
        url,
        {
            method: "PUT",
            body: JSON.stringify({ order: order.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return Order.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function deleteOrder(team_subdomain: string, project_token: string, order: Order): Promise<boolean> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id;

    return plotFetch(url, { method: "DELETE" }, true)
        .then((data) => {
            if (data.ok) {
                return true;
            }
            throw new Error("Network error.");
        })
        .catch(() => {
            return false;
        });
}

export function createOrderCheckIn(
    team_subdomain: string,
    project_token: string,
    order: Order,
    order_checkin: OrderCheckIn
): Promise<any> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id +
        "/order_check_ins";

    return plotFetch(
        url,
        {
            method: "POST",
            body: JSON.stringify({ order_check_in: order_checkin.toApiObject(), files: [] }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function getOrderCheckins(
    team_subdomain: string,
    project_token: string,
    order: Order,
    timezone: string
): Promise<OrderCheckIn[]> {
    const url = `${Helper.baseUrlAltSubdomain(
        team_subdomain ? team_subdomain : "projects"
    )}/projects/${project_token}/orders/${order.id}/order_check_ins`;

    const options = {
        method: "get",
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data.map((checkin: any) => OrderCheckIn.fromApiObject(checkin, timezone));
        })
        .catch(() => {
            return [];
        });
}

export function getOrderAudits(
    team_subdomain: string,
    project_token: string,
    order: Order
): Promise<ChangeHistoryPoint[]> {
    const url = `${Helper.baseUrlAltSubdomain(
        team_subdomain ? team_subdomain : "projects"
    )}/projects/${project_token}/orders/${order.id}/order_audits`;

    const options = {
        method: "get",
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data.map((checkin: any) => ChangeHistoryPoint.fromApiObject(checkin));
        })
        .catch(() => {
            return [];
        });
}
