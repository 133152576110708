/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Area.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Area } from "./Area";

import { AreaBreakTime } from "./AreaBreakTime";
import { AreaExceptionDay } from "./AreaExceptionDay";
import { AreaHasEquipment } from "./AreaHasEquipment";
import { AreaPoint } from "./AreaPoint";
import { AreaRadius } from "./AreaRadius";
import { Delivery } from "./Delivery";
import { Equipment } from "./Equipment";
import { Layer } from "./Layer";
import { NotificationList } from "./NotificationList";

export abstract class AreaModel {
    id: number;
    layerId: number;
    areaName: string | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    areaType: string | null;
    color: string | null;
    uuid: string | null;
    outline: string | null;
    startTime: Dayjs | null;
    endTime: Dayjs | null;
    opacity: number | null;
    bannedVehicles: string | null;
    details: string | null;
    sort: number | null;
    areasIndex: number | null;
    isWaypoint: boolean | null;
    isDropOff: boolean | null;
    breakStart: Dayjs | null;
    breakEnd: Dayjs | null;
    enableBreakTime: boolean | null;
    openDate: Dayjs | null;
    closeDate: Dayjs | null;
    defaultWaypoint: string | null;
    equipmentId: number | null;
    iconAnchor: string | null;
    icon: string | null;
    iconWidth: number | null;
    iconUnits: string | null;
    feature: any | null;
    archived: boolean;
    notificationLists: NotificationList[] | undefined;
    layer: Layer | null | undefined;
    equipment: Equipment | null | undefined;
    areaBreakTimes: AreaBreakTime[] | undefined;
    areaExceptionDays: AreaExceptionDay[] | undefined;
    areaPoints: AreaPoint[] | undefined;
    areaRadiuses: AreaRadius[] | undefined;
    areaHasEquipment: AreaHasEquipment[] | undefined;
    dropOffAreaForDeliveries: Delivery[] | undefined;
    waypointForDeliveries: Delivery[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        layerId: number,
        areaName: string | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        areaType: string | null,
        color: string | null,
        uuid: string | null,
        outline: string | null,
        startTime: Dayjs | null,
        endTime: Dayjs | null,
        opacity: number | null,
        bannedVehicles: string | null,
        details: string | null,
        sort: number | null,
        areasIndex: number | null,
        isWaypoint: boolean | null,
        isDropOff: boolean | null,
        breakStart: Dayjs | null,
        breakEnd: Dayjs | null,
        enableBreakTime: boolean | null,
        openDate: Dayjs | null,
        closeDate: Dayjs | null,
        defaultWaypoint: string | null,
        equipmentId: number | null,
        iconAnchor: string | null,
        icon: string | null,
        iconWidth: number | null,
        iconUnits: string | null,
        feature: any | null,
        archived: boolean,
        notificationLists: NotificationList[] | undefined,
        layer: Layer | null | undefined,
        equipment: Equipment | null | undefined,
        areaBreakTimes: AreaBreakTime[] | undefined,
        areaExceptionDays: AreaExceptionDay[] | undefined,
        areaPoints: AreaPoint[] | undefined,
        areaRadiuses: AreaRadius[] | undefined,
        areaHasEquipment: AreaHasEquipment[] | undefined,
        dropOffAreaForDeliveries: Delivery[] | undefined,
        waypointForDeliveries: Delivery[] | undefined
    ) {
        this.id = id;
        this.layerId = layerId;
        this.areaName = areaName;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.areaType = areaType;
        this.color = color;
        this.uuid = uuid;
        this.outline = outline;
        this.startTime = startTime;
        this.endTime = endTime;
        this.opacity = opacity;
        this.bannedVehicles = bannedVehicles;
        this.details = details;
        this.sort = sort;
        this.areasIndex = areasIndex;
        this.isWaypoint = isWaypoint;
        this.isDropOff = isDropOff;
        this.breakStart = breakStart;
        this.breakEnd = breakEnd;
        this.enableBreakTime = enableBreakTime;
        this.openDate = openDate;
        this.closeDate = closeDate;
        this.defaultWaypoint = defaultWaypoint;
        this.equipmentId = equipmentId;
        this.iconAnchor = iconAnchor;
        this.icon = icon;
        this.iconWidth = iconWidth;
        this.iconUnits = iconUnits;
        this.feature = feature;
        this.archived = archived;
        this.notificationLists = notificationLists;
        this.layer = layer;
        this.equipment = equipment;
        this.areaBreakTimes = areaBreakTimes;
        this.areaExceptionDays = areaExceptionDays;
        this.areaPoints = areaPoints;
        this.areaRadiuses = areaRadiuses;
        this.areaHasEquipment = areaHasEquipment;
        this.dropOffAreaForDeliveries = dropOffAreaForDeliveries;
        this.waypointForDeliveries = waypointForDeliveries;
    }
     

    static fromApiObject(json: any, timezone: string): Area {
        const newObject = new Area(
            json["id"],
            json["layer_id"],
            json["area_name"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["area_type"],
            json["color"],
            json["uuid"],
            json["outline"],
            json["start_time"] ? dayjs(json["start_time"]).tz(timezone) : null,
            json["end_time"] ? dayjs(json["end_time"]).tz(timezone) : null,
            json["opacity"],
            json["banned_vehicles"],
            json["details"],
            json["sort"],
            json["areas_index"],
            json["is_waypoint"],
            json["is_drop_off"],
            json["break_start"] ? dayjs(json["break_start"]).tz(timezone) : null,
            json["break_end"] ? dayjs(json["break_end"]).tz(timezone) : null,
            json["enable_break_time"],
            json["open_date"] ? dayjs(json["open_date"]).tz(timezone) : null,
            json["close_date"] ? dayjs(json["close_date"]).tz(timezone) : null,
            json["default_waypoint"],
            json["equipment_id"],
            json["icon_anchor"],
            json["icon"],
            json["icon_width"],
            json["icon_units"],
            json["feature"],
            json["archived"],
            json["notification_lists"]?.map((item: any) => NotificationList.fromApiObject(item, timezone)),
            json["layer"] == null ? json["layer"] : Layer.fromApiObject(json["layer"], timezone),
            json["equipment"] == null ? json["equipment"] : Equipment.fromApiObject(json["equipment"], timezone),
            json["area_break_times"]?.map((item: any) => AreaBreakTime.fromApiObject(item, timezone)),
            json["area_exception_days"]?.map((item: any) => AreaExceptionDay.fromApiObject(item, timezone)),
            json["area_points"]?.map((item: any) => AreaPoint.fromApiObject(item, timezone)),
            json["area_radiuses"]?.map((item: any) => AreaRadius.fromApiObject(item, timezone)),
            json["area_has_equipment"]?.map((item: any) => AreaHasEquipment.fromApiObject(item, timezone)),
            json["drop_off_area_for_deliveries"]?.map((item: any) => Delivery.fromApiObject(item, timezone)),
            json["waypoint_for_deliveries"]?.map((item: any) => Delivery.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            layer_id: this.layerId,
            area_name: this.areaName,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            area_type: this.areaType,
            color: this.color,
            uuid: this.uuid,
            outline: this.outline,
            start_time: this.startTime,
            end_time: this.endTime,
            opacity: this.opacity,
            banned_vehicles: this.bannedVehicles,
            details: this.details,
            sort: this.sort,
            areas_index: this.areasIndex,
            is_waypoint: this.isWaypoint,
            is_drop_off: this.isDropOff,
            break_start: this.breakStart,
            break_end: this.breakEnd,
            enable_break_time: this.enableBreakTime,
            open_date: this.openDate,
            close_date: this.closeDate,
            default_waypoint: this.defaultWaypoint,
            equipment_id: this.equipmentId,
            icon_anchor: this.iconAnchor,
            icon: this.icon,
            icon_width: this.iconWidth,
            icon_units: this.iconUnits,
            feature: this.feature,
            archived: this.archived,
            notification_lists: this.notificationLists?.map(item => item.toApiObject()),
            area_break_times_attributes: this.areaBreakTimes?.map(item => item.toApiObject()),
            area_exception_days_attributes: this.areaExceptionDays?.map(item => item.toApiObject()),
            area_points_attributes: this.areaPoints?.map(item => item.toApiObject()),
            area_radiuses_attributes: this.areaRadiuses?.map(item => item.toApiObject()),
            area_has_equipment: this.areaHasEquipment?.map(item => item.toApiObject()),
            drop_off_area_for_deliveries: this.dropOffAreaForDeliveries?.map(item => item.toApiObject()),
            waypoint_for_deliveries: this.waypointForDeliveries?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Area {
        const clone = Object.create(this) as Area;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
