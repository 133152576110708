import Helper from "../../Helper";
import { Material } from "../../models/Material";
import { plotFetch } from "../Helper/ApiRequest";

export function createMaterial(
    team_subdomain: string,
    project_token: string,
    material: Material,
    material_group_id: number | null,
    timezone: string
): Promise<Material | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials";

    return plotFetch(
        url,
        {
            method: "POST",
            body: JSON.stringify({ material: material.toApiObject(), material_group_id: material_group_id }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return Material.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function updateMaterial(
    team_subdomain: string,
    project_token: string,
    material: Material,
    timezone: string
): Promise<Material | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials/" +
        material.id;

    return plotFetch(
        url,
        {
            method: "PUT",
            body: JSON.stringify({ material: material.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return Material.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function deleteMaterial(team_subdomain: string, project_token: string, material: Material): Promise<boolean> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials/" +
        material.id;

    return plotFetch(url, { method: "DELETE" }, true)
        .then((data) => {
            if (data.ok) {
                return true;
            }
            throw new Error("Network error.");
        })
        .catch(() => {
            return false;
        });
}
