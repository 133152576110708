/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Team.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Team } from "./Team";

import { Audit } from "./Audit";
import { Delivery } from "./Delivery";
import { DeliveryHasTeam } from "./DeliveryHasTeam";
import { MaterialGroup } from "./MaterialGroup";
import { Message } from "./Message";
import { NotificationList } from "./NotificationList";
import { Permission } from "./Permission";
import { Project } from "./Project";
import { UsersMostRecentProject } from "./UsersMostRecentProject";
import { VendorLink } from "./VendorLink";

export type ssoProvider = "unset" | "okta" | "saml";

export const ssoProviderValues = {"unset":0,"okta":1,"saml":2};

export abstract class TeamModel {
    id: number;
    teamName: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    subdomain: string | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    websiteUrl: string | null;
    emailDomain: string | null;
    lat: number | null;
    lon: number | null;
    phoneNumber: string | null;
    autoApproveToken: string | null;
    accountToken: string | null;
    brandColors: string | null;
    initials: string | null;
    ssoProvider: ssoProvider;
    ssoProviderData: any;
    ssoEnforced: boolean;
    gcCanManageCompanyInfo: boolean;
    audits: Audit[] | undefined;
    notificationLists: NotificationList[] | undefined;
    vendorLinks: VendorLink[] | undefined;
    deliveryHasTeams: DeliveryHasTeam[] | undefined;
    deliveriesIsVendorFor: Delivery[] | undefined;
    materialGroups: MaterialGroup[] | undefined;
    permissions: Permission[] | undefined;
    usersMostRecentProjects: UsersMostRecentProject[] | undefined;
    messages: Message[] | undefined;
    ownedProjects: Project[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        teamName: string | null,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string | null,
        zipCode: string | null,
        subdomain: string | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        websiteUrl: string | null,
        emailDomain: string | null,
        lat: number | null,
        lon: number | null,
        phoneNumber: string | null,
        autoApproveToken: string | null,
        accountToken: string | null,
        brandColors: string | null,
        initials: string | null,
        ssoProvider: ssoProvider,
        ssoProviderData: any,
        ssoEnforced: boolean,
        gcCanManageCompanyInfo: boolean,
        audits: Audit[] | undefined,
        notificationLists: NotificationList[] | undefined,
        vendorLinks: VendorLink[] | undefined,
        deliveryHasTeams: DeliveryHasTeam[] | undefined,
        deliveriesIsVendorFor: Delivery[] | undefined,
        materialGroups: MaterialGroup[] | undefined,
        permissions: Permission[] | undefined,
        usersMostRecentProjects: UsersMostRecentProject[] | undefined,
        messages: Message[] | undefined,
        ownedProjects: Project[] | undefined
    ) {
        this.id = id;
        this.teamName = teamName;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.subdomain = subdomain;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.websiteUrl = websiteUrl;
        this.emailDomain = emailDomain;
        this.lat = lat;
        this.lon = lon;
        this.phoneNumber = phoneNumber;
        this.autoApproveToken = autoApproveToken;
        this.accountToken = accountToken;
        this.brandColors = brandColors;
        this.initials = initials;
        this.ssoProvider = ssoProvider;
        this.ssoProviderData = ssoProviderData;
        this.ssoEnforced = ssoEnforced;
        this.gcCanManageCompanyInfo = gcCanManageCompanyInfo;
        this.audits = audits;
        this.notificationLists = notificationLists;
        this.vendorLinks = vendorLinks;
        this.deliveryHasTeams = deliveryHasTeams;
        this.deliveriesIsVendorFor = deliveriesIsVendorFor;
        this.materialGroups = materialGroups;
        this.permissions = permissions;
        this.usersMostRecentProjects = usersMostRecentProjects;
        this.messages = messages;
        this.ownedProjects = ownedProjects;
    }
     

    static fromApiObject(json: any, timezone: string): Team {
        const newObject = new Team(
            json["id"],
            json["team_name"],
            json["address_1"],
            json["address_2"],
            json["city"],
            json["state"],
            json["zip_code"],
            json["subdomain"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["website_url"],
            json["email_domain"],
            json["lat"],
            json["lon"],
            json["phone_number"],
            json["auto_approve_token"],
            json["account_token"],
            json["brand_colors"],
            json["initials"],
            json["sso_provider"],
            json["sso_provider_data"],
            json["sso_enforced"],
            json["gc_can_manage_company_info"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["notification_lists"]?.map((item: any) => NotificationList.fromApiObject(item, timezone)),
            json["vendor_links"]?.map((item: any) => VendorLink.fromApiObject(item, timezone)),
            json["delivery_has_teams"]?.map((item: any) => DeliveryHasTeam.fromApiObject(item, timezone)),
            json["deliveries_is_vendor_for"]?.map((item: any) => Delivery.fromApiObject(item, timezone)),
            json["material_groups"]?.map((item: any) => MaterialGroup.fromApiObject(item, timezone)),
            json["permissions"]?.map((item: any) => Permission.fromApiObject(item, timezone)),
            json["users_most_recent_projects"]?.map((item: any) => UsersMostRecentProject.fromApiObject(item, timezone)),
            json["messages"]?.map((item: any) => Message.fromApiObject(item, timezone)),
            json["owned_projects"]?.map((item: any) => Project.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            team_name: this.teamName,
            address_1: this.address1,
            address_2: this.address2,
            city: this.city,
            state: this.state,
            zip_code: this.zipCode,
            subdomain: this.subdomain,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            website_url: this.websiteUrl,
            email_domain: this.emailDomain,
            lat: this.lat,
            lon: this.lon,
            phone_number: this.phoneNumber,
            auto_approve_token: this.autoApproveToken,
            account_token: this.accountToken,
            brand_colors: this.brandColors,
            initials: this.initials,
            sso_provider: this.ssoProvider,
            sso_provider_data: this.ssoProviderData,
            sso_enforced: this.ssoEnforced,
            gc_can_manage_company_info: this.gcCanManageCompanyInfo,
            audits: this.audits?.map(item => item.toApiObject()),
            notification_lists: this.notificationLists?.map(item => item.toApiObject()),
            vendor_links: this.vendorLinks?.map(item => item.toApiObject()),
            delivery_has_teams: this.deliveryHasTeams?.map(item => item.toApiObject()),
            deliveries_is_vendor_for: this.deliveriesIsVendorFor?.map(item => item.toApiObject()),
            material_groups: this.materialGroups?.map(item => item.toApiObject()),
            permissions: this.permissions?.map(item => item.toApiObject()),
            users_most_recent_projects: this.usersMostRecentProjects?.map(item => item.toApiObject()),
            messages: this.messages?.map(item => item.toApiObject()),
            owned_projects: this.ownedProjects?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Team {
        const clone = Object.create(this) as Team;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
