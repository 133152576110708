/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Notification.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Notification } from "./Notification";

import { Message } from "./Message";
import { User } from "./User";

export abstract class NotificationModel {
    id: number;
    messageId: number;
    userId: number;
    read: boolean;
    readAt: Dayjs | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    message: Message | null | undefined;
    user: User | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        messageId: number,
        userId: number,
        read: boolean,
        readAt: Dayjs | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        message: Message | null | undefined,
        user: User | null | undefined
    ) {
        this.id = id;
        this.messageId = messageId;
        this.userId = userId;
        this.read = read;
        this.readAt = readAt;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.message = message;
        this.user = user;
    }
     

    static fromApiObject(json: any, timezone: string): Notification {
        const newObject = new Notification(
            json["id"],
            json["message_id"],
            json["user_id"],
            json["read"],
            json["read_at"] ? dayjs(json["read_at"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["message"] == null ? json["message"] : Message.fromApiObject(json["message"], timezone),
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            message_id: this.messageId,
            user_id: this.userId,
            read: this.read,
            read_at: this.readAt,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Notification {
        const clone = Object.create(this) as Notification;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
