/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `WeatherAlert.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { WeatherAlert } from "./WeatherAlert";

import { ProjectsWeatherAlert } from "./ProjectsWeatherAlert";

export abstract class WeatherAlertModel {
    id: number;
    alertTime: Dayjs | null;
    alertType: string | null;
    alertId: string | null;
    alertInfo: any | null;
    projectsWeatherAlerts: ProjectsWeatherAlert[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        alertTime: Dayjs | null,
        alertType: string | null,
        alertId: string | null,
        alertInfo: any | null,
        projectsWeatherAlerts: ProjectsWeatherAlert[] | undefined
    ) {
        this.id = id;
        this.alertTime = alertTime;
        this.alertType = alertType;
        this.alertId = alertId;
        this.alertInfo = alertInfo;
        this.projectsWeatherAlerts = projectsWeatherAlerts;
    }
     

    static fromApiObject(json: any, timezone: string): WeatherAlert {
        const newObject = new WeatherAlert(
            json["id"],
            json["alert_time"] ? dayjs(json["alert_time"]).tz(timezone) : null,
            json["alert_type"],
            json["alert_id"],
            json["alert_info"],
            json["projects_weather_alerts"]?.map((item: any) => ProjectsWeatherAlert.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            alert_time: this.alertTime,
            alert_type: this.alertType,
            alert_id: this.alertId,
            alert_info: this.alertInfo,
            projects_weather_alerts: this.projectsWeatherAlerts?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): WeatherAlert {
        const clone = Object.create(this) as WeatherAlert;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
