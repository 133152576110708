import { notification } from "antd";
import { trackPromise } from "react-promise-tracker";

export function getDeliveryAttachments(project_token: string, delivery_unique_token: string): Promise<any> {
    const url = `/projects/${project_token}/deliveries/${delivery_unique_token}/attachments`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to get attachments for delivery");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function createDeliveryAttachment(
    project_token: string,
    delivery_unique_token: string,
    form_data: FormData
): Promise<any> {
    const url = `/projects/${project_token}/deliveries/${delivery_unique_token}/attachments`;

    return trackPromise(
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: form_data,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to create attachment for delivery");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteDeliveryAttachment(
    project_token: string,
    delivery_unique_token: string,
    attachment_id: number
): Promise<any> {
    const url = `/projects/${project_token}/deliveries/${delivery_unique_token}/attachments/${attachment_id}`;

    return trackPromise(
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            body: JSON.stringify({}),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to delete attachment for delivery");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                notification.error({
                    message: "Error Deleting Attachment",
                    description: "Failed to delete attachment for delivery",
                });
                return null;
            })
    );
}
