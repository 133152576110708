/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `VendorCache.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { VendorCache } from "./VendorCache";

export type integrationSource = "user_upload" | "procore" | "procore_sandbox" | "autodesk_construction" | "cmic" | "primavera_p6" | "microsoft_project" | "asta_power_project" | "faker";

export const integrationSourceValues = {"user_upload":0,"procore":1,"procore_sandbox":2,"autodesk_construction":3,"cmic":4,"primavera_p6":5,"microsoft_project":6,"asta_power_project":7,"faker":8};

export abstract class VendorCacheModel {
    id: number;
    cacheableType: string | null;
    cacheableId: number | null;
    integrationSource: integrationSource;
    vendorRecordId: string | null;
    data: any | null;
    lastSynced: Dayjs | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        cacheableType: string | null,
        cacheableId: number | null,
        integrationSource: integrationSource,
        vendorRecordId: string | null,
        data: any | null,
        lastSynced: Dayjs | null,
        createdAt: Dayjs,
        updatedAt: Dayjs
    ) {
        this.id = id;
        this.cacheableType = cacheableType;
        this.cacheableId = cacheableId;
        this.integrationSource = integrationSource;
        this.vendorRecordId = vendorRecordId;
        this.data = data;
        this.lastSynced = lastSynced;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
     

    static fromApiObject(json: any, timezone: string): VendorCache {
        const newObject = new VendorCache(
            json["id"],
            json["cacheable_type"],
            json["cacheable_id"],
            json["integration_source"],
            json["vendor_record_id"],
            json["data"],
            json["last_synced"] ? dayjs(json["last_synced"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            cacheable_type: this.cacheableType,
            cacheable_id: this.cacheableId,
            integration_source: this.integrationSource,
            vendor_record_id: this.vendorRecordId,
            data: this.data,
            last_synced: this.lastSynced,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): VendorCache {
        const clone = Object.create(this) as VendorCache;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
