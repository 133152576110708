/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `EquipmentTempClosure.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { EquipmentTempClosure } from "./EquipmentTempClosure";

import { Equipment } from "./Equipment";

export abstract class EquipmentTempClosureModel {
    id: number;
    closureName: string | null;
    closureStart: Dayjs | null;
    closureEnd: Dayjs | null;
    equipmentId: number | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    equipment: Equipment | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        closureName: string | null,
        closureStart: Dayjs | null,
        closureEnd: Dayjs | null,
        equipmentId: number | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        equipment: Equipment | null | undefined
    ) {
        this.id = id;
        this.closureName = closureName;
        this.closureStart = closureStart;
        this.closureEnd = closureEnd;
        this.equipmentId = equipmentId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.equipment = equipment;
    }
     

    static fromApiObject(json: any, timezone: string): EquipmentTempClosure {
        const newObject = new EquipmentTempClosure(
            json["id"],
            json["closure_name"],
            json["closure_start"] ? dayjs(json["closure_start"]).tz(timezone) : null,
            json["closure_end"] ? dayjs(json["closure_end"]).tz(timezone) : null,
            json["equipment_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["equipment"] == null ? json["equipment"] : Equipment.fromApiObject(json["equipment"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            closure_name: this.closureName,
            closure_start: this.closureStart,
            closure_end: this.closureEnd,
            equipment_id: this.equipmentId,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): EquipmentTempClosure {
        const clone = Object.create(this) as EquipmentTempClosure;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
