/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `UsersMostRecentProject.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { UsersMostRecentProject } from "./UsersMostRecentProject";

import { Team } from "./Team";
import { User } from "./User";

export abstract class UsersMostRecentProjectModel {
    id: number;
    userId: number | null;
    teamId: number | null;
    projectUniqueToken: string | null;
    lastAccessed: Dayjs | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    activeOrder: number | null;
    user: User | null | undefined;
    team: Team | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        userId: number | null,
        teamId: number | null,
        projectUniqueToken: string | null,
        lastAccessed: Dayjs | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        activeOrder: number | null,
        user: User | null | undefined,
        team: Team | null | undefined
    ) {
        this.id = id;
        this.userId = userId;
        this.teamId = teamId;
        this.projectUniqueToken = projectUniqueToken;
        this.lastAccessed = lastAccessed;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.activeOrder = activeOrder;
        this.user = user;
        this.team = team;
    }
     

    static fromApiObject(json: any, timezone: string): UsersMostRecentProject {
        const newObject = new UsersMostRecentProject(
            json["id"],
            json["user_id"],
            json["team_id"],
            json["project_unique_token"],
            json["last_accessed"] ? dayjs(json["last_accessed"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["active_order"],
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone),
            json["team"] == null ? json["team"] : Team.fromApiObject(json["team"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            user_id: this.userId,
            team_id: this.teamId,
            project_unique_token: this.projectUniqueToken,
            last_accessed: this.lastAccessed,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            active_order: this.activeOrder,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): UsersMostRecentProject {
        const clone = Object.create(this) as UsersMostRecentProject;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
