/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `MagicToken.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { MagicToken } from "./MagicToken";

import { User } from "./User";

export abstract class MagicTokenModel {
    id: number;
    userId: number | null;
    magicToken: string;
    expiresAt: Dayjs;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    returnUrl: string | null;
    magicTokenHash: string | null;
    user: User | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        userId: number | null,
        magicToken: string,
        expiresAt: Dayjs,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        returnUrl: string | null,
        magicTokenHash: string | null,
        user: User | null | undefined
    ) {
        this.id = id;
        this.userId = userId;
        this.magicToken = magicToken;
        this.expiresAt = expiresAt;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.returnUrl = returnUrl;
        this.magicTokenHash = magicTokenHash;
        this.user = user;
    }
     

    static fromApiObject(json: any, timezone: string): MagicToken {
        const newObject = new MagicToken(
            json["id"],
            json["user_id"],
            json["magic_token"],
            dayjs(json["expires_at"]).tz(timezone),
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["return_url"],
            json["magic_token_hash"],
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            user_id: this.userId,
            magic_token: this.magicToken,
            expires_at: this.expiresAt,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            return_url: this.returnUrl,
            magic_token_hash: this.magicTokenHash,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): MagicToken {
        const clone = Object.create(this) as MagicToken;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
