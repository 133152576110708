import Helper from "../../Helper";
import Permission from "../../Permission";
import TeamInfo from "../../teamInfo";
import UserInfo from "../../userInfo";

/**
 * Get project info for a given token
 * @param token Unique token of the project to GET
 * @returns project info, or null if not found
 */
export function getProjectInfo(token: string): Promise<any> {
    if (token) {
        const url = `/projects/${token}`;
        const options = { method: "GET" };

        return Helper.plotFetch(url, options, true)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            });
    }
    return Promise.resolve(null);
}

export function getProjectInfoWithSubdomain(subdomain: string, token: string): Promise<any> {
    if (token && subdomain) {
        const url = `${Helper.baseUrlAltSubdomain(subdomain)}/projects/${token}`;
        const options = { method: "GET" };

        return Helper.plotFetch(url, options, true)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return null;
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            });
    }

    return Promise.resolve(null);
}

/**
 * Update a project by token in the backend
 * @param token Unique token of the project to PUT
 * @param updatedProjectInfo updated project info to PUT
 */
export function putProjectInfo(token: string, updatedProjectInfo): Promise<object> {
    const url = `/projects/${token}`;
    const options = {
        method: "put",
        body: JSON.stringify({
            project: {
                project_name: updatedProjectInfo.project_name,
                address_1: updatedProjectInfo.address_1,
                address_2: updatedProjectInfo.address_2,
                city: updatedProjectInfo.city,
                state: updatedProjectInfo.state,
                zip_code: updatedProjectInfo.zip_code,
                lat: updatedProjectInfo.lat,
                lon: updatedProjectInfo.lon,
                detail: updatedProjectInfo.detail,
                contacts_list: updatedProjectInfo.contacts_list,
                delivery_hours_start: updatedProjectInfo.delivery_hours_start,
                delivery_hours_end: updatedProjectInfo.delivery_hours_end,
                delivery_hours_all_day: updatedProjectInfo.delivery_hours_all_day,
                use_advanced_delivery_logic: updatedProjectInfo.use_advanced_delivery_logic,
                use_advanced_delivery_logic_exception_days:
                    updatedProjectInfo.use_advanced_delivery_logic_exception_days,
                use_advanced_delivery_logic_trades: updatedProjectInfo.use_advanced_delivery_logic_trades,
                use_advanced_delivery_logic_map_marker: updatedProjectInfo.use_advanced_delivery_logic_map_marker,
                use_advanced_delivery_logic_equipment: updatedProjectInfo.use_advanced_delivery_logic_equipment,
                use_advanced_delivery_logic_teams: updatedProjectInfo.use_advanced_delivery_logic_teams,
                use_advanced_delivery_logic_pending: updatedProjectInfo.use_advanced_delivery_logic_pending,
                delivery_created_approval_require_teams: updatedProjectInfo.delivery_created_approval_require_teams,
                delivery_scheduled_approval_require_teams: updatedProjectInfo.delivery_scheduled_approval_require_teams,
                delivery_approval_require_gc: updatedProjectInfo.delivery_approval_require_gc,
                delivery_approval_require_final: updatedProjectInfo.delivery_approval_require_final,
                share_eta: updatedProjectInfo.share_eta,
                share_delivery_vehicle: updatedProjectInfo.share_delivery_vehicle,
                delivery_logging: updatedProjectInfo.delivery_logging,
                enable_vendor_scheduling: updatedProjectInfo.enable_vendor_scheduling,
                timezone: updatedProjectInfo.timezone,
                work_week_days: updatedProjectInfo.work_week_days,
                enable_lightning_alerts: updatedProjectInfo.enable_lightning_alerts,
                lightning_alert_distance: updatedProjectInfo.lightning_alert_distance,
                lightning_all_clear_duration: updatedProjectInfo.lightning_all_clear_duration,
                enable_weather_severe: updatedProjectInfo.enable_weather_severe,
                enable_weather_threats: updatedProjectInfo.enable_weather_threats,
                lightning_alerts_channel: updatedProjectInfo.lightning_alerts_channel,
                weather_threats_channel: updatedProjectInfo.weather_threats_channel,
                weather_severe_channel: updatedProjectInfo.weather_severe_channel,
                min_delivery_schedule: updatedProjectInfo.min_delivery_schedule,
                max_delivery_schedule: updatedProjectInfo.max_delivery_schedule,
                notification_lists_teams: updatedProjectInfo.notification_lists_teams,
                notification_lists_areas: updatedProjectInfo.notification_lists_areas,
                notification_lists_equipments: updatedProjectInfo.notification_lists_equipments,
                gc_can_approve_trade_deliveries: updatedProjectInfo.gc_can_approve_trade_deliveries,
                require_vendor_info_deliveries: updatedProjectInfo.require_vendor_info_deliveries,
                use_deliveries: updatedProjectInfo.use_deliveries,
                use_lead_times: updatedProjectInfo.use_lead_times,
                lead_times_default_lead_time: updatedProjectInfo.lead_times_default_lead_time,
                lead_times_default_check_in_frequency: updatedProjectInfo.lead_times_default_check_in_frequency,
                lead_times_review_duration: updatedProjectInfo.lead_times_review_duration,
                lead_times_order_delay: updatedProjectInfo.lead_times_order_delay,
                lead_times_required_on_jobsite_require_gc: updatedProjectInfo.lead_times_required_on_jobsite_require_gc,
                lead_times_default_activity_dependency: updatedProjectInfo.lead_times_default_activity_dependency,
                lead_times_default_shakeout_days: updatedProjectInfo.lead_times_default_shakeout_days,
                action_items_email_day: updatedProjectInfo.action_items_email_day,
                action_items_email_time: updatedProjectInfo.action_items_email_time,
                overview_email_day: updatedProjectInfo.overview_email_day,
                overview_email_time: updatedProjectInfo.overview_email_time,
                integration_daily_log_deliveries: updatedProjectInfo.integration_daily_log_deliveries,
                integration_submittals_deliveries: updatedProjectInfo.integration_submittals_deliveries,
                integration_import_members: updatedProjectInfo.integration_import_members,
                integration_id: updatedProjectInfo.integration_id,
                command_center_settings: updatedProjectInfo.command_center_settings,
                integration_source_company_id: updatedProjectInfo.integration_source_company_id,
                integration_source_project_id: updatedProjectInfo.integration_source_project_id,
                procurement_sync_time: updatedProjectInfo.procurement_sync_time,
                procurement_sync_do_submittals: updatedProjectInfo.procurement_sync_do_submittals,
                procurement_sync_do_activities: updatedProjectInfo.procurement_sync_do_activities,
            },
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

/**
 * Update a projects detail by token
 * @param token Unique token of the project to PUT
 * @param detail_id id of the detail to be updated
 * @param detail_string string of updated detail
 */
export function putProjectDetailInfo(token: string, detail_id: number, detail_text: string): Promise<boolean> {
    const url = `/projects/${token}`;
    const options = {
        method: "put",
        body: JSON.stringify({
            project: {
                detail_attributes: {
                    id: detail_id,
                    detail_text: detail_text,
                },
            },
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

/**
 * Update a projects values by token
 * @param token Unique token of the project to PUT
 * @param values_attributes updated values
 */
export function putProjectValueInfo(token: string, values_attributes): Promise<boolean> {
    const url = `/projects/${token}`;

    const options = {
        method: "put",
        body: JSON.stringify({
            project: {
                values_attributes: values_attributes,
            },
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

/**
 * Get the access level for the current user of a project with the current team
 * @param token Unique token of the project to check access level on
 * @param subdomain subdomain to use to find project (defaults to current subdomain if unspecified)
 * @returns number indicating access level (See: AccessLevel), or null if failed to default to 0
 */
export function getProjectAccessLevel(
    token: string,
    subdomain: string | null = null
): Promise<{ level: number; message: string }> {
    if (token) {
        const url = `${subdomain ? Helper.baseUrlAltSubdomain(subdomain) : ""}/projects/${token}/access_level`;
        const options = { method: "GET" };

        return Helper.plotFetch(url, options, true)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return {
                    level: 0,
                    message: "Failed to GET access level in check response",
                };
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return {
                    level: 0,
                    message: "Failed to GET access level",
                };
            });
    }

    return Promise.resolve({
        level: 0,
        message: "Invalid Project Token, skipped GET",
    });
}

/**
 * Gets the members of a project for any user that has their current team on the project
 * @param token Unique token of the project to get the members for
 * @returns Object of structs of various member information
 */
export function getProjectMembers(token: string): Promise<any> {
    const url = `/projects/${token}/members`;
    const options = { method: "GET" };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .catch(() => {
            return null;
        });
}

export function getTeamProjectLead(token: string, team_id: number, project_id: number): Promise<UserInfo | null> {
    return getProjectMembers(token).then((response) => {
        if (!response) {
            return null;
        }

        const lead = response
            .find((team) => team.id === team_id)
            .users.map((user) => new UserInfo(user))
            .find((user) => user.permission(Permission.is_lead, team_id, project_id));

        return lead || null;
    });
}

/**
 * Gets boolean if current user is a lead on a project
 * @param token Unique token of the project to get the members for
 * @returns Object of structs of various member information
 * !! IMPORTANT !! - user permission model makes this function obsolete. Use the permission model call instead
 *this.context.userHasPermission(
            Permission.is_lead,
            this.context.currentTeam?.id,
            this.context.currentProjectInfo.id
        )
 */
export function getIsTeamLead(token: string, subdomain: string = ""): Promise<{ is_team_lead: boolean }> {
    const baseUrl = subdomain && subdomain.length > 0 ? Helper.baseUrlAltSubdomain(subdomain) : "";
    const url = `${baseUrl}/projects/${token}/leads/me`;
    const options = { method: "GET" };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}

/**
 * Gets the publicly available members for a project
 * @param token Unique token of the project to get the members for
 * @returns Object of structs of various member information
 */
export function getPublicProjectMembers(token: string, subdomain: string): Promise<TeamInfo[]> {
    const baseUrl = subdomain && subdomain.length > 0 ? Helper.baseUrlAltSubdomain(subdomain) : "";
    const url = `${baseUrl}/projects/${token}/public_members`;
    const options = { method: "GET" };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response.map((team) => new TeamInfo(team));
        })
        .catch(() => {
            return [];
        });
}

/**
 * Request to join a Project with a given Team
 * @param token Unique token of the project to request to join
 * @param joiningTeamID ID of the team requesting to join
 * @returns String indicating type of success or failure
 */
export function requestToJoinProjectWithTeam(token: string, joiningTeamID: number): Promise<string> {
    const url = `/projects/${token}/request_to_join`;
    const options = {
        method: "post",
        body: JSON.stringify({
            joining_team_id: joiningTeamID,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            if (data.message === "unclaimed project") {
                return "Approved";
            } else {
                return "Requested";
            }
        })
        .catch(() => {
            return "Failed";
        });
}

export function userRequestToJoinProjectWithTeam(token: string, joiningTeamID: number): Promise<string | null> {
    const url = `/projects/${token}/user_request_to_join`;
    const options = {
        method: "post",
        body: JSON.stringify({
            joining_team_id: joiningTeamID,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            if (data.message) {
                return data.message;
            }
        })
        .catch(() => {
            return null;
        });
}

/**
 * Claim a project with a team
 * @param token Unique token of the project to request to join
 * @param claimingTeamID ID of the team claiming
 * @returns String indicating type of success or failure
 */
export function claimProjectWithTeam(token: string, claimingTeamID): Promise<any | null> {
    const url = `/projects/${token}/claim`;
    const options = {
        method: "post",
        body: JSON.stringify({
            claiming_team_id: claimingTeamID,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function addTeam(project_token: string, team_id: number, type?: string): Promise<boolean> {
    const baseUrl = `/projects/${project_token}/invite_team`;
    const url = `${baseUrl}${type ? `?type=${type}` : ""}`;
    const options = {
        method: "post",
        body: JSON.stringify({
            team: { id: team_id },
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export function getAutoApproveToken(project_token: string): Promise<string> {
    const url = `/projects/${project_token}/auto_approve_token`;

    return Helper.plotFetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            throw new Error("Failed to get auto approve token");
        })
        .then((response) => {
            return response.token;
        })
        .catch(() => {
            return null;
        });
}

export function shareCompanyListing(
    project_token: string,
    shared_contacts: { contact_info: string }[]
): Promise<boolean> {
    const url = `/projects/${project_token}/share_company_listing`;
    const options = {
        method: "post",
        body: JSON.stringify({
            shared_contacts: shared_contacts,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export function gcResendTradeUserInvite(
    project_token: string,
    trade_id: number,
    contacts: string[],
    role: string
): Promise<string> {
    const url = `/projects/${project_token}/invite`;
    const options = {
        method: "post",
        body: JSON.stringify({
            trade_id: trade_id,
            contacts: contacts,
            role: role,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function gcRevokeTradeUserInvite(
    project_token: string,
    trade_id: number,
    invite_token: string,
    contact_type: string
): Promise<string> {
    const url = `/projects/${project_token}/gc_revoke_trade_user_invite`;
    const options = {
        method: "put",
        body: JSON.stringify({
            trade_id: trade_id,
            invite_token: invite_token,
            contact_type: contact_type,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function markUserForRemovalFromTeamFromProject(
    project_token: string,
    team_id: number,
    user_id: number
): Promise<boolean> {
    const url = `/projects/${project_token}/mark_user_for_removal_from_team_from_project`;
    const options = {
        method: "put",
        body: JSON.stringify({
            team_id: team_id,
            user_id: user_id,
        }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export function putProjectUpdateMembers(teamInfo: TeamInfo, project_token: string): Promise<object> {
    const url = `/teams/${teamInfo.id}/projects/${project_token}/update_members`;
    const options = {
        method: "put",
        body: JSON.stringify({ users: teamInfo.users }),
    };

    return Helper.plotFetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status == 422) {
                return response.json();
            }

            throw new Error("Failed to update team members");
        })
        .then((response) => {
            if (response.error) {
                return null;
            }
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function getItemLeads(
    projectToken: string,
    listType: "Area" | "Team" | "Equipment",
    itemId: number
): Promise<any> {
    const url = `/projects/${projectToken}/notification_lists?type=${listType}&id=${itemId}`;

    return Helper.plotFetch(url)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            // No response means no notification leads
            // This means default to ALL GC Team Leads
            // Do nothing
        });
}
