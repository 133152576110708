import { sub } from "date-fns";
import { OrderModel } from "./Order.model";

export class Order extends OrderModel {
    approveBy(orderDelay: number): Date | null {
        if (this.orderBy) {
            return sub(this.orderBy.toDate(), { days: orderDelay });
        }

        return null;
    }
}
