/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `NotificationList.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { NotificationList } from "./NotificationList";

import { NotificationListsUser } from "./NotificationListsUser";
import { Project } from "./Project";

export abstract class NotificationListModel {
    id: number;
    notificationListableType: string;
    notificationListableId: number;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    projectId: number;
    project: Project | null | undefined;
    notificationListsUsers: NotificationListsUser[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        notificationListableType: string,
        notificationListableId: number,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        projectId: number,
        project: Project | null | undefined,
        notificationListsUsers: NotificationListsUser[] | undefined
    ) {
        this.id = id;
        this.notificationListableType = notificationListableType;
        this.notificationListableId = notificationListableId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.projectId = projectId;
        this.project = project;
        this.notificationListsUsers = notificationListsUsers;
    }
     

    static fromApiObject(json: any, timezone: string): NotificationList {
        const newObject = new NotificationList(
            json["id"],
            json["notification_listable_type"],
            json["notification_listable_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["project_id"],
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["notification_lists_users"]?.map((item: any) => NotificationListsUser.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            notification_listable_type: this.notificationListableType,
            notification_listable_id: this.notificationListableId,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            project_id: this.projectId,
            notification_lists_users: this.notificationListsUsers?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): NotificationList {
        const clone = Object.create(this) as NotificationList;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
