/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `DeliveriesEquipment.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { DeliveriesEquipment } from "./DeliveriesEquipment";

import { Delivery } from "./Delivery";
import { Equipment } from "./Equipment";

export abstract class DeliveriesEquipmentModel {
    id: number;
    equipmentId: number;
    deliveryId: number;
    delivery: Delivery | null | undefined;
    equipment: Equipment | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        equipmentId: number,
        deliveryId: number,
        delivery: Delivery | null | undefined,
        equipment: Equipment | null | undefined
    ) {
        this.id = id;
        this.equipmentId = equipmentId;
        this.deliveryId = deliveryId;
        this.delivery = delivery;
        this.equipment = equipment;
    }
     

    static fromApiObject(json: any, timezone: string): DeliveriesEquipment {
        const newObject = new DeliveriesEquipment(
            json["id"],
            json["equipment_id"],
            json["delivery_id"],
            json["delivery"] == null ? json["delivery"] : Delivery.fromApiObject(json["delivery"], timezone),
            json["equipment"] == null ? json["equipment"] : Equipment.fromApiObject(json["equipment"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            equipment_id: this.equipmentId,
            delivery_id: this.deliveryId,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): DeliveriesEquipment {
        const clone = Object.create(this) as DeliveriesEquipment;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
