/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `AreaBreakTime.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { AreaBreakTime } from "./AreaBreakTime";

import { Area } from "./Area";

export abstract class AreaBreakTimeModel {
    id: number;
    breakName: string | null;
    breakStart: Dayjs | null;
    breakEnd: Dayjs | null;
    areaId: number | null;
    createdAt: Dayjs;
    updatedAt: Dayjs;
    area: Area | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        breakName: string | null,
        breakStart: Dayjs | null,
        breakEnd: Dayjs | null,
        areaId: number | null,
        createdAt: Dayjs,
        updatedAt: Dayjs,
        area: Area | null | undefined
    ) {
        this.id = id;
        this.breakName = breakName;
        this.breakStart = breakStart;
        this.breakEnd = breakEnd;
        this.areaId = areaId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.area = area;
    }
     

    static fromApiObject(json: any, timezone: string): AreaBreakTime {
        const newObject = new AreaBreakTime(
            json["id"],
            json["break_name"],
            json["break_start"] ? dayjs(json["break_start"]).tz(timezone) : null,
            json["break_end"] ? dayjs(json["break_end"]).tz(timezone) : null,
            json["area_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            break_name: this.breakName,
            break_start: this.breakStart,
            break_end: this.breakEnd,
            area_id: this.areaId,
            created_at: this.createdAt,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): AreaBreakTime {
        const clone = Object.create(this) as AreaBreakTime;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
